import { Job } from "./job/job";

export class Order {
  deeplinkId: string = '';
  supplierId: string = '';
  orderNumber: string = '';
  synchronizationDisabled: boolean = false;
  title: string = '';
  description?: string | null;
  supplierContactPhone: string = '';
  customerName: string = '';
  isoLanguage?: string | null;
  intlLocaleId?: string | null;
  jobs: Job[] = [];

  public constructor(init?: Partial<Order>) {
    Object.assign(this, init);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static fromJson(item: any): Order {
    const order = new Order();
    Object.assign(order, item);

    if(item.jobs) {
      order.jobs = Job.fromJsonList(item.jobs);
    }
    return order;
  }
}
