/**
 * The valid states for a job modification request.
 */
export enum JobModificationRequestState {
    /**
     * The end user created a new job modification request.
     */
    New = 'New',

    /**
     * The job modification request has been downloaded for processing.
     */
    Synchronized = 'Synchronized',

    /**
     * The job modification request has been confirmed by the dispatcher.
     */
    Accepted = 'Accepted',

    /**
     * The job modification request has been rejected by the dispatcher.
     */
    Rejected = 'Rejected',
}
