/* eslint-disable @typescript-eslint/no-unused-vars */

import { HttpClient, HttpErrorResponse, HttpResponse, HttpStatusCode } from "@angular/common/http";
import { Subject, Observable, catchError, map, of, throwError } from "rxjs";
import { Injectable, InjectionToken, inject, signal } from "@angular/core";
import { AppStateService, BaseContentService, ErrorModal, ErrorModalService, ProblemDetails, StringUtility } from "@infosystem/baf-angular-web-common";
import { Order } from "../models/order";
import { GetOrderResponse } from "../models/get-order-response";
import { Supplier } from "../models/supplier";
import { Job } from "../models/job/job";
import { FlexibleTimeSpecificationDefinition } from "../models/flexible-time-specification-definition";
import { ConfirmCheckoutResponse } from "../models/confirm-checkout-response";
import { DateUtility } from '../utilities/date-utility';
import { FlexibleDateTime } from '../models/flexible-date-time';

export const API_URL = new InjectionToken<string>("API_URL");

/**
 * Order service.
 *
 * Note: The job (and other objects) are set first, so dependent components/services can
 * short-circuit processing while the Order is not loaded.
 */
@Injectable({
  providedIn: "root",
})
export class OrderService extends BaseContentService<Order> {
  private apiBaseUrl: string = inject(API_URL);
  private apiUrl: string;
  readonly order  = signal<Order | null>(null);
  readonly supplier = signal<Supplier | null>(null);
  readonly job = signal<Job | null>(null);

  /** The order Id from the browser Url. */
  public orderId: string | null = null;

  /** The job Id from the browser Url. */
  public jobId: string | null = null;


  public constructor(
    http: HttpClient,
    appStateService: AppStateService,
    errorModalService: ErrorModalService
  ) {
    super(http, appStateService, errorModalService);
    this.apiUrl = this.apiBaseUrl + 'order';
  }

  /**
   * Sets the order and job Id (usually read from the browser Url).
   * @param orderId The order deeplink Id.
   * @param jobId The job Id (in the order).
   */
  public setId(orderId: string | null, jobId: string | null){
    this.orderId = orderId;
    this.jobId = jobId;

    if(this.order()){
      this.setJob(this.order());
    }
  }

  /**
   * Sets the order.
   * Updates the Job if the current job Id is present in the order.
   * @param order The order.
   */
  private setOrder(order: Order | null){
    this.setJob(order);
    this.order.set(order);
  }

  /**
   * Sets the job.
   * @param order The order
   */
  private setJob(order: Order | null){
    if(this.jobId == null) {
      this.job.set(null);
    } else {
      const job = order?.jobs.find((job) => job.id === this.jobId);
      this.job.set(job ?? null);
    }
  }

  /**
   * Loads an order from the Api server.
   *
   * The result is available in @field order, @field supplier, @field job.
   */
  public getOrder(deeplinkId: string): Observable<Order | null> {
    this.appStateService.busy = true;

    const _url = StringUtility.format(this.apiUrl + '/{0}', deeplinkId);

    return this.http.get<GetOrderResponse>(_url, { headers: this._httpHeaders, observe: 'response' }).pipe(
      map((res: HttpResponse<GetOrderResponse>) => {
        this.appStateService.busy = false;

        if (!res || res?.status !== 200) return null;

        const data = GetOrderResponse.fromJson(res.body);
        this.supplier.set(data.supplier);
        this.setOrder(data.order);
        return data.order;
      }),
      catchError((error: HttpErrorResponse) => {
        this.appStateService.busy = false;
        if(error.status == HttpStatusCode.NotFound) {
          return of(null);
        } else {
          return this.handleError(this, error);
        }
      })
    );
  }

  /**
   * Confirms a Job checkout.
   * @param orderDeeplinkId The order deeplink Id.
   * @param jobId The job Id that is being confirmed.
   * @param pickUpFlexibleTime The flexible time specification for the pick-up time.
   * @param pickUpTime The time for the pick-up.
   * @param orderDeeplinkId The pick-up comment.
   */
  public confirmCheckout(
    orderDeeplinkId: string,
    jobId: string,
    pickUpFlexibleTime: FlexibleTimeSpecificationDefinition,
    pickUpTime: Date | null,
    comment: string | null
  ): Observable<Order | null> {
    this.appStateService.busy = true;

    const _url = this.apiUrl + '/confirm-checkout';

    // Only using the time, when the flexible specification is from/until
    if(pickUpFlexibleTime != FlexibleTimeSpecificationDefinition.From &&
      pickUpFlexibleTime != FlexibleTimeSpecificationDefinition.Until) {
        pickUpTime = null;
    }

    const data = {
      orderDeeplinkId: orderDeeplinkId,
      jobId: jobId,
      pickUpTimeSpecification: pickUpFlexibleTime,
      pickUpTime: DateUtility.toDateTimeOffsetString(pickUpTime),
      comment: comment
    };

    return this.http.post(_url, data, {
      headers: this._httpHeaders,
      observe: 'response'
    }).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((res: any) => {
        this.appStateService.busy = false;

        if (res?.status === 200 || res?.status === 201) {
          const data = ConfirmCheckoutResponse.fromJson(res.body);
          this.setOrder(data.order);
          return data.order;
        } else {
          return null;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(this, error);
      })
    );
  }

  /**
   * Requests a job extension.
   * @param orderDeeplinkId The order deeplink Id.
   * @param jobId The job Id that is being adjusted.
   * @param jobEndDateTime The new job end date.
   * @param pickUpFlexibleTime The flexible time specification for the pick-up time.
   * @param pickUpTime The time for the pick-up.
   * @param orderDeeplinkId The pick-up comment.
   */
  public extendJob(
    orderDeeplinkId: string,
    jobId: string,
    jobEndDateTime: FlexibleDateTime,
    pickUpFlexibleTime: FlexibleTimeSpecificationDefinition,
    pickUpTime: Date | null,
    comment: string | null
  ): Observable<Order | null> {
    this.appStateService.busy = true;
    const _url = this.apiUrl + '/extend';

    // Only using the time, when the flexible specification is from/until
    if(pickUpFlexibleTime != FlexibleTimeSpecificationDefinition.From &&
      pickUpFlexibleTime != FlexibleTimeSpecificationDefinition.Until) {
        pickUpTime = null;
    }

    const data = {
      orderDeeplinkId: orderDeeplinkId,
      jobId: jobId,
      jobEndDateTime: {
        timestamp: DateUtility.toDateTimeOffsetString(jobEndDateTime.timestamp),
        flexibleSpecification: jobEndDateTime.flexibleSpecification
      },
      pickUpTimeSpecification: pickUpFlexibleTime,
      pickUpTime: DateUtility.toDateTimeOffsetString(pickUpTime),
      comment: comment
    };

    return this.http.post(_url, data, {
      headers: this._httpHeaders,
      observe: 'response'
    }).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((res: any) => {
        this.appStateService.busy = false;

        if (res?.status === 200 || res?.status === 201) {
          const data = ConfirmCheckoutResponse.fromJson(res.body);
          this.setOrder(data.order);
          return data.order;
        } else {
          return null;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(this, error);
      })
    );
  }

  public override get(id: string | number): Observable<Order> {
    throw new Error("Not supported.");
  }

  public override search(searchterm: Subject<string>): Observable<Order[]> {
    throw new Error("Not supported.");
  }

  public override load(filter?: string | number | undefined): Observable<Order[]> {
    throw new Error("Not supported.");
  }
  public override insert(item: Order): Observable<Order> {
    throw new Error("Not supported.");
  }

  public override update(id: string | number, item: Order): Observable<Order> {
    throw new Error("Not supported.");
  }

  public override delete(id: string | number, item: Order): Observable<boolean> {
    throw new Error("Not supported.");
  }

  public getData(): Observable<Order> {
    throw new Error("Not supported.");
  }

  protected override handleError(service: BaseContentService<Order>, httpErrorResponse: HttpErrorResponse) {
    if (this.ignoreErrorHandling === true) {
      return throwError(() => httpErrorResponse.error);
    }

    service.appStateService.busy = false;
    setTimeout(() => {
      this.appStateService.loading = false;
    }, 300);

    if (!service.errorModalService) {
      // Return an observable with a user-facing error message.
      return throwError(() => new Error("An error occurred, please try again later."));
    }

    console.error("An error occurred:", httpErrorResponse);

    const error = httpErrorResponse.error;
    const errorModal: ErrorModal = new ErrorModal();
    errorModal.error = httpErrorResponse;

    // status=0 is connection problems. Handle it differently for now, otherwise BAF components will show an empty error.
    if(httpErrorResponse.status != 0 &&
      httpErrorResponse.headers.get('content-type')?.startsWith('application/problem+json')
    ) {
      errorModal.problemDetails = new ProblemDetails(error.type, error.title ?? error.detail, error.status, error.detail);
    }

    service.errorModalService.show(errorModal);
    return of();
  }
}

