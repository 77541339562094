import { FlexibleTimeSpecificationDefinition } from './flexible-time-specification-definition';
import { SupplierLogoAlignment } from './supplier-logo-alignment';

export class Supplier {
  id: string = '';
  name: string = '';
  logoUrl: string = '';
  logoAlignment: SupplierLogoAlignment = SupplierLogoAlignment.Center;
  allowedTimeSpecificationOptions: FlexibleTimeSpecificationDefinition[] | null = null;

  public constructor(init?: Partial<Supplier>) {
    Object.assign(this, init);
  }

  public static fromJson(item: unknown): Supplier {
    const supplier = Object.assign(new Supplier(), item);

    return supplier;
  }
}
