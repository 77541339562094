import { FlexibleDateTime } from "../flexible-date-time";
import { JobModificationRequestState } from "./job-modification-request-state";
import { JobModificationRequestType } from "./job-modification-request-type";

export class JobModificationRequest {
  id!: string;
  jobId: string = "";
  requestType!: JobModificationRequestType;
  requestProcessingState: JobModificationRequestState = JobModificationRequestState.New;
  createdDate!: Date;
  processedDate?: Date | null;
  requesterPhone?: string | null;
  /**
   * The end of the job.
   * Used for JobModificationRequestType.Extend
   */
  jobEndDateTime?: FlexibleDateTime | null;
  jobEndConfirmed: boolean = false;
  pickUpDateTime?: FlexibleDateTime | null;
  pickUpComment?: string | null;

  public constructor(init?: Partial<JobModificationRequest>) {
    Object.assign(this, init);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static fromJsonList(array: unknown[] | null): JobModificationRequest[] {
    return array != null
      ? array.map((json: unknown) => JobModificationRequest.fromJson(json))
      : new Array<JobModificationRequest>();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static fromJson(item: any): JobModificationRequest {
    const obj = Object.assign(new JobModificationRequest(), item);

    if(item.createdDate){
      obj.createdDate = new Date(item.createdDate);
    }
    if(item.processedDate){
      obj.processedDate = new Date(item.processedDate);
    }

    if(item.jobEndDateTime){
      obj.jobEndDateTime = FlexibleDateTime.fromJson(item.jobEndDateTime);
    }
    if(item.pickUpDateTime){
      obj.pickUpDateTime = FlexibleDateTime.fromJson(item.pickUpDateTime);
    }
    return obj;
  }
}
