/**
 * Valid changes the customer/end user can request.
 */
export enum JobModificationRequestType {
  /**
   * Confirm the end and pick-up date.
   */
  ConfirmCheckout = 'ConfirmCheckout',

  /**
   * Extend the rental job.
   */
  Extend = 'Extend',
}
