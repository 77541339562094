import { FlexibleDateTime } from "../flexible-date-time";
import { GpsCoordinate } from "../gps-coordinate";
import { Resource } from "./resource";
import { JobModificationRequest } from "./job-modification-request";
import { JobModificationRequestState } from './job-modification-request-state';

export class Job {
  id!: string;
  customerReference : string = "";
  siteInfo: string = "";
  siteAddress?: string | null;
  siteAddressGps?: GpsCoordinate | null;
  handoverDateTime!: FlexibleDateTime;
  startDateTime!: FlexibleDateTime;
  endDateTime!: FlexibleDateTime;
  durationDays: number = 0;
  endConfirmed: boolean = false;
  automaticExtensionActive: boolean = false;
  automaticCheckoutActive: boolean = false;
  pickUpDateTime!: FlexibleDateTime;
  finished: boolean = false;
  resource!: Resource;
  accessories?: string | null;
  modificationRequests: JobModificationRequest[] = [];

  public constructor(init?: Partial<Job>) {
    Object.assign(this, init);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static fromJsonList(array: unknown[] | null): Job[] {
    return array != null ? array.map((json: unknown) => Job.fromJson(json)) : new Array<Job>();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static fromJson(item: any): Job {
    const job = Object.assign(new Job(), item);

    if(item.handoverDateTime){
      job.handoverDateTime = FlexibleDateTime.fromJson(item.handoverDateTime);
    }
    if(item.startDateTime){
      job.startDateTime = FlexibleDateTime.fromJson(item.startDateTime);
    }
    if(item.endDateTime){
      job.endDateTime = FlexibleDateTime.fromJson(item.endDateTime);
    }
    if(item.pickUpDateTime){
      job.pickUpDateTime = FlexibleDateTime.fromJson(item.pickUpDateTime);
    }

    if(item.modificationRequests){
      job.modificationRequests = JobModificationRequest.fromJsonList(item.modificationRequests);
    }
    return job;
  }

  public hasOpenModificationRequest(): boolean {
    if(!this.modificationRequests || this.modificationRequests.length === 0) {
      return false;
    }

    return -1 !== this.modificationRequests.findIndex(x =>
      x.requestProcessingState !== JobModificationRequestState.Accepted &&
      x.requestProcessingState !== JobModificationRequestState.Rejected);
  }
}
