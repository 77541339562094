/**
 * Supplier logo alignment.
 */
export enum SupplierLogoAlignment {

  /**
   * Center
   */
  Center = 'Fix',

  /**
   * Left
   */
  Left = 'Left',

  /**
   * Right
   */
  Right = 'Right',
}
